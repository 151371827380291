.about-me-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 30px;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

h2{
  font-size: 55px;
  margin: 0;
}

.about-me-text {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.about-me-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-me-image img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 4px solid #fff;
  transition: transform 0.3s ease;
}

.about-me-image img:hover {
  transform: scale(1.05);
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .about-me-container {
    grid-template-columns: 1fr; /* Adjust grid-template-columns for mobile */
    gap: 20px;
    margin: 10px;
    padding: 10px;
  }

  .about-me-text {
    font-size: 14px;
  }

  .about-me-image {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Reset the order to its original value */
    order: -1;
  }

  .about-me-image img {
    max-width: 150px; /* Adjust the image size for mobile */
  }
}
