.home-page {
  background-image: url('../../../public/imgs/edgar-rodriguez.jpg');
  background-size: cover;
  background-position: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.home-content {
  text-align: center;
  color: #fff;
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home-content h1 {
  font-size: 150px;
  margin-bottom: 2px;
  transition: opacity 0.5s ease-in-out;
}

.home-content p {
  font-size: 24px;
  margin-bottom: 40px;
  transition: opacity 0.5s ease-in-out;
}

/* Media queries for mobile devices */

@media (max-width: 768px) {
  .home-page {
    background-position: right center; /* Set background position to start from the left on mobile */
  }
  .home-content h1 {
    font-size: 60px; /* Adjust font size for smaller screens */
  }

  .home-content p {
    font-size: 18px; /* Adjust font size for smaller screens */
    margin-bottom: 20px; /* Adjust margin for smaller screens */
  }
}
