/* Navbar.css */

/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
}

/* General styles for the navigation */
.navbar {
  background-color: #fff;
  padding: 15px; /* Increase padding to make the nav bar thicker */
}

.desktop-menu {
  display: flex;
  justify-content: center;
}

.desktop-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.desktop-menu li {
  margin: 0 20px; /* Increase the margin for more spacing between menu items */
}

.desktop-menu a {
  color: #333;
  text-decoration: none;
  font-size: 18px; /* Increase font size */
  transition: color 0.3s ease-in-out;
}

.desktop-menu a:hover {
  color: #555;
}

/* Mobile Menu */
.mobile-menu {
  display: none; /* Hide the mobile menu by default */
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center the hamburger icon horizontally */
  cursor: pointer;
  width: 30px; /* Adjust the width of the hamburger icon */
}

.line {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: background-color 0.3s ease-in-out;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.menu.open {
  height: auto;
}

.menu li {
  margin: 10px 0;
}

.menu a {
  color: #333;
  text-decoration: none;
  font-size: 18px; /* Increase font size */
  font-weight: 600; /* Make it a little thicker */
  transition: color 0.3s ease-in-out;
}

.menu a:hover {
  color: #555;
}

/* Show the mobile menu on mobile devices when isMenuOpen is true */
@media (max-width: 768px) {
  .desktop-menu {
    display: none; /* Hide the desktop menu on mobile */
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
