/* ServicePage.css */
.service-title{
margin-left: 40px;
}

.service-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    justify-items: center;
    margin: 30px;
  }
  
  .service-card {
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    transition: transform 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-icon {
    font-size: 36px;
    color: #555;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: #f9f9f9;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .service-icon:hover {
    transform: scale(1.1);
  }
  
  .service-card h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .service-card p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
  }
  
  @media (max-width: 768px) {

    .service-title{
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    }
  }